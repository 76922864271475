import {Container, Row, Col, Image} from "react-bootstrap";
import i18n from "../i18n";


interface IPremiumUpgrades {
    title: string,
    description: string,
    img: {
        src: string
    }
}


const PremiumUpgradesComponent: React.FC = () => {

    const { t } = i18n

    const data = [
        {
            title: t('Interior Finishing Options'),
            description: t('Streamline the construction process with our integrated interior finishing layer for wall and ceiling panels. This eliminates the need for drywall installation and painting, saving valuable time during construction. Choose from over 10 stunning designs, ranging from solid colors to woodgrain imitations, to create a polished and personalized interior that’s ready to enjoy immediately.'),
            img: {
                src: "/images/models/interior_finishing.png"
            }
        },
        {
            title: t('Metal Panels'),
            description: t('Take your PakVille unit\'s curb appeal to the next level with our premium screw-in metal roof panels. Durable and stylish, these 26-gauge metal panels aren\'t just eye-catching—they\'re built to withstand the elements, ensuring your unit stays safe and looks great for years to come.'),
            img: {
                src: "/images/models/metal_panels.png"
            }
        },
        {
            title: t('Cooling and Heating'),
            description: t('Elevate the comfort and style of your PakVille unit with the LG ARTCOOL™ AC/Heating unit. This sleek unit not only offers efficient cooling and heating but also integrates smoothly into your space with its modern design. Experience unparalleled convenience as you can access and control your air conditioner anytime, anywhere using a Wi-Fi-equipped device.'),
            img: {
                src: "/images/models/cooling_and_heating.png"
            }
        },

    ]

    return (<>
        <Container fluid>
            <Container className="text-center">
                <Row>
                    {
                        data.map((premiumUpgrade: IPremiumUpgrades, index: number) => (
                            <Col key={index} lg={4} className="mb-4">
                                <Row>
                                    <Col xs={12} className="order-2 order-md-1">
                                        <Image className="mw-100 rounded" src={premiumUpgrade.img.src} alt={t(premiumUpgrade.title)} />
                                    </Col>
                                    <Col xs={12} className="mt-4 mt-md-0 order-1 order-md-2">
                                        <h2 className="modelHeader">{t(premiumUpgrade.title)}</h2>
                                        <p className="mt-4">{t(premiumUpgrade.description)}</p>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

        </Container>
    </>)
}

export default PremiumUpgradesComponent;