import {useParams, Navigate} from 'react-router-dom';
import {NavLink} from "react-router-dom";
import i18n from "../i18n"
import {Container, Row, Col, Card, Button, Image, ListGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faGlobe, faEnvelope, faPlus} from "@fortawesome/free-solid-svg-icons";
import {facebookLink, flatPhoneNumber, getBaseUrl, instagramLink, youTubeLink, linkedinLink} from '../utils/Toolbox';
import {faFacebook, faInstagram, faLinkedin, faYoutube} from '@fortawesome/free-brands-svg-icons';
import DocumentComponent from "../components/Document.component";

interface IContact {
    phone: string,
    email: string,
    first_name: string,
    last_name: string,
    title: string,
    vcf: string,
    avatar: string,
    description: string,
}

export default function VCard() {

    const {t} = i18n;

    const params = useParams();
    const contactParam = (params.contact || '').toLowerCase();
    if (contactParam === undefined) {
        return (<Navigate to="/"/>)
    }

    const contacts: Record<string, IContact> = {
        'parinaz_pakniat': {
            phone: '(514) 885-1361',
            email: 'parinaz@pakville.ca',
            first_name: 'Parinaz',
            last_name: 'Pakniat',
            vcf: '/vcards/ParinazPakniat.vcf',
            title: 'Founder & CEO / Fondatrice et PDG',
            avatar: '/images/story/ParinazPakniatAvatar.jpg',
            description: 'Your Dream Home, Now Even Better / Votre maison de rêve, maintenant encore mieux'
        },
        'maxime': {
            phone: '(514) 553-9112',
            email: "maxime@pakville.ca",
            first_name: 'Maxime',
            last_name: 'Ridoux',
            vcf: '/vcards/Maxime.vcf',
            title: 'Directeur des ventes',
            avatar: '/images/story/MaximeRidoux.jpg',
            description: 'Votre maison de rêve, maintenant encore mieux'
        },
    };

    const contact = contacts[contactParam];
    if (contact === undefined) {
        return (<Navigate to="/"/>)
    }

    const full_name = contact.first_name + ' ' + contact.last_name;
    return (<>

        <DocumentComponent title={full_name}/>

        <div style={{height: '100vh', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Container>
                <Row>
                    <Col xs={{span: 10, offset: 1}} lg={{span: 6, offset: 3}} className='text-center'>

                        <Card className='vcard'>
                            <Card.Header className='p-0'>
                                <Button variant="primary" style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    padding: '0.25rem 0.5rem'
                                }} title={t('add_to_contacts')}>
                                    <NavLink to={contact.vcf} target="_blank" download><FontAwesomeIcon
                                        icon={faPlus}/></NavLink>
                                </Button>
                                <Image src={contact.avatar} style={{maxWidth: '30%'}}
                                       roundedCircle className="mt-4 mb-4" alt={full_name}
                                       title={full_name}/>
                                <h1 className="modelHeader text-center text-light">{full_name}</h1>
                                <h2 style={{fontSize: '1rem', color: '#e5e5e5'}}>{contact.title}</h2>

                                <div className='d-flex justify-content-around mt-4 p-2 border-top '>

                                    <a rel="noreferrer" href={`tel:${flatPhoneNumber(contact.phone)}`}
                                       className='border-end' style={{flex: '2 0'}}>
                                        <FontAwesomeIcon icon={faPhone}/>
                                        <br/>
                                        {t('Call')}
                                    </a>

                                    <a rel="noreferrer" href={`mailto:${contact.email}`} style={{flex: '2 0'}}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                        <br/>
                                        {t('email')}
                                    </a>
                                </div>
                            </Card.Header>

                            <Card.Body className='p-1'>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>
                                        <Card.Link href={`tel:${flatPhoneNumber(contact.phone)}`}>
                                            <div className='vcardInfoContainer'>
                                                <FontAwesomeIcon icon={faPhone}/>
                                                {contact.phone}
                                            </div>
                                        </Card.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Card.Link href={`mailto:${contact.email}`}>
                                            <div className='vcardInfoContainer'>
                                                <FontAwesomeIcon icon={faEnvelope}/>
                                                {contact.email}
                                            </div>
                                        </Card.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Card.Link href={'/'} target='_blank'>
                                            <div className='vcardInfoContainer'>
                                                <FontAwesomeIcon icon={faGlobe}/>
                                                {getBaseUrl()}
                                            </div>
                                        </Card.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Card.Link href={youTubeLink()} target='_blank'>
                                            <FontAwesomeIcon icon={faYoutube}/>
                                        </Card.Link>
                                        <Card.Link href={facebookLink()} target='_blank'>
                                            <FontAwesomeIcon icon={faFacebook}/>
                                        </Card.Link>
                                        <Card.Link href={instagramLink()} target='_blank'>
                                            <FontAwesomeIcon icon={faInstagram}/>
                                        </Card.Link>
                                        <Card.Link href={linkedinLink()} target='_blank'>
                                            <FontAwesomeIcon icon={faLinkedin}/>
                                        </Card.Link>

                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Card.Text style={{fontSize: '14px'}}>
                                            {contact.description}
                                        </Card.Text>
                                    </ListGroup.Item>
                                </ListGroup>

                            </Card.Body>

                            <Card.Footer>
                                <Button variant="primary">
                                    <NavLink to={contact.vcf} target="_blank" download><FontAwesomeIcon
                                        icon={faPlus}/> {t('add_to_contacts')} </NavLink>
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

    </>)
}

