import HeroComponent from "../components/Hero.component";
import DocumentComponent from "../components/Document.component";
import ScheduleComponent from "../components/Schedule.component";
import i18n from "../i18n"
import { Container, Row, Col } from "react-bootstrap";

const faqs = Array.from({ length: 14 }, (_, index) => index + 1);

export default function Faq() {

    const { t } = i18n;

    return (<>

        <DocumentComponent title={t('frequently_asked_questions')} />

        <HeroComponent title={t('frequently_asked_questions')} />

        <Container>

            {faqs.map((number: number, index: number) => (
                <Row key={index}>
                    <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="mb-3">
                        <h5>{index + 1}. {t(`faq_list.${index}.question`)}</h5>
                    </Col>

                    <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} className="mb-4">
                        <p>{t(`faq_list.${index}.answer`)}</p>
                    </Col>

                </Row>
            ))}

        </Container>

        <ScheduleComponent />

        <br />
        <br />
        <br />
    </>)
}
