import {Container, Row, Col, Image, Button} from "react-bootstrap";
import i18n from "../../i18n";
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {NavLink} from "react-router-dom";
import {getUrlLangPrefix} from "../../utils/Local";

const PakVertScreen: React.FC = () => {

    const {t, language} = i18n;
    const langPrefix = getUrlLangPrefix()

    return (<>
        <Container fluid className="screenContainer p-0 pt-4 pt-lg-0">

            <Container className="flexContainer pakvertContainer">
                <Row>
                    <Col xs={12} lg={6}>
                        <div className="flexContainer" style={{gap: '8px'}}>
                            <h2>{t('_screen.1.title')}</h2>
                            <h3>{t('_screen.1.subtitle')}</h3>
                            <p>{t('_screen.1.description')}</p>
                            <NavLink to={`${langPrefix}/${t('_route.the-technology')}`}>
                                <Button className="btn-primary">{t('learn_more')}</Button>
                            </NavLink>
                        </div>
                    </Col>

                    <Col xs={12} lg={6}>
                        <div className="flexContainer">
                            <AnimationOnScroll animateIn="animate__fadeInRightBig" animateOnce>
                                <Image src={`/images/home/PakVert_Technology_${language}.png`} rounded className="mw-100"
                                       alt={`${t('_screen.1.title')}`}/>
                            </AnimationOnScroll>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    </>)
}

export default PakVertScreen;