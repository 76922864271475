import {Col, Container, Row, Image} from "react-bootstrap";
import i18n from "../i18n";
import {flatPhoneNumber} from "../utils/Toolbox";
import {ReactComponent as FaMail} from '../assets/icons/faMail.svg';
import {ReactComponent as FaPhone} from '../assets/icons/faPhone.svg';
import {ReactComponent as FaMapMarker} from '../assets/icons/faMapMarker.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedin, faYoutube, faSquareFacebook, faInstagramSquare} from "@fortawesome/free-brands-svg-icons";
import {NavLink} from "react-router-dom";
import {getSalesPhoneNumber, getUrlLangPrefix} from "../utils/Local";

const FooterComponent = () => {

    const {t} = i18n;
    const langPrefix = getUrlLangPrefix()
    const year = (new Date().getFullYear());
    const phoneNumber = getSalesPhoneNumber();
    const email = process.env.REACT_APP_SALES_EMAIL || '';
    const address = '618 Av. Meloche, Dorval, QC H9P 2P4'
    const googleMap = 'https://maps.app.goo.gl/hLsSuZKwaGbW984R6'
    const rbq = '5840-0672-01'

    return (<>
        <Container fluid className="footerContainer">
            <Container>

                <Row style={{textAlign: 'center', paddingTop: '4rem'}}>

                    <Col xs={12}>
                        <Image src="/images/logo/pakville.svg" style={{maxWidth: '250px'}} alt="PakVille"></Image>
                    </Col>

                    <Col xs={12}>
                        <div className="footerContactInfoContainer">
                            {t('_footer_contact_note')}
                        </div>
                    </Col>

                    <Col xs={12}>
                        <div className="footerContactInfoContainer">
                            <div>
                                <FaMail width={'1.5rem'} height={'1.5rem'}/><a href={`mailto:${email}`}
                                                                               rel="noreferrer">{email}</a>
                            </div>
                            <div>
                                <FaPhone width={'1.5rem'} height={'1.5rem'}/><a
                                href={`tel:${flatPhoneNumber(phoneNumber)}`} rel="noreferrer">{phoneNumber}</a>
                            </div>
                            <div>
                                <FaMapMarker width={'1.5rem'} height={'1.5rem'}/><a href={googleMap} target="_blank"
                                                                                    rel="noreferrer">{address}</a>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} className="mt-3">
                        <hr style={{width: '60px', margin: '1rem auto'}}></hr>
                        <div>
                            RBQ #: {rbq}
                        </div>
                    </Col>
                </Row>

                <hr></hr>

                <Row style={{paddingBottom: '4rem'}}>

                    <Col xs={12} md={4}>
                        <div className="footerCopyrightContainer">
                            {t('copyright')} © {year} PakVille Construction Corp, {t('all_rights_reserved')}
                        </div>
                    </Col>

                    <Col xs={12} md={4}>
                        <div className="footerSocialMediaContainer">
                            <a href="https://www.youtube.com/channel/UCOETzq4CMluyhH87DrwMW5g" target="_blank"
                               rel="noreferrer"><FontAwesomeIcon icon={faYoutube} size="2x" color="gray"/></a>
                            <a href="https://www.facebook.com/profile.php?id=61552975003426" target="_blank"
                               rel="noreferrer"><FontAwesomeIcon icon={faSquareFacebook} size="2x" color="gray"/></a>
                            <a href="https://www.instagram.com/pakville" target="_blank"
                               rel="noreferrer"><FontAwesomeIcon icon={faInstagramSquare} size="2x" color="gray"/></a>
                            <a href="https://www.linkedin.com/company/pakville" target="_blank"
                               rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size="2x" color="gray"/></a>
                        </div>
                    </Col>

                    <Col xs={12} md={4}>
                        <div className="footerPrivacyContainer">
                            <NavLink to={`${langPrefix}/${t('_route.terms-of-service')}`}
                                     className='text-center text-lg-end'>
                                {t('terms_of_service')}
                            </NavLink>
                            <NavLink to={`${langPrefix}/${t('_route.privacy-policy')}`}
                                     className='text-center text-lg-end'>
                                {t('privacy_policy')}
                            </NavLink>
                            <NavLink to={`${langPrefix}/${t('_route.refund-policy')}`}
                                     className='text-center text-lg-end'>
                                {t('refund_policy')}
                            </NavLink>
                        </div>
                    </Col>

                </Row>

            </Container>
        </Container>
    </>)
}

export default FooterComponent;