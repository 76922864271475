import CarouselComponent from "../components/Carousel.component";
import DocumentComponent from "../components/Document.component";
import ScheduleComponent from "../components/Schedule.component";
import IntroScreen from "../components/screens/intro.components";
import PakVertScreen from "../components/screens/PakVert.component";
import InstallationScreen from "../components/screens/Installation.component";
import BenefitsScreen from "../components/screens/Benefits.component";
import ExhibitionScreen from "../components/screens/Exhibition.component";
import { Container, Row, Col } from "react-bootstrap";
import BlogScreen from "../components/screens/LatestBlogs.component";
import ShopScreen from "../components/screens/Shop.component";

export default function Home() {

    return (<>

        <DocumentComponent title={''} />

        <CarouselComponent />

        <IntroScreen />

        <PakVertScreen />

        <Container fluid className="0">
            <Row>
                <Col xs={12} className="order-2 order-md-1 p-0">
                    <InstallationScreen />
                    <ShopScreen />
                    <BenefitsScreen />
                </Col>
                <Col xs={12} className="order-1 order-md-2 p-0">
                    <ScheduleComponent />
                </Col>
            </Row>
        </Container>

        <BlogScreen />

        <ExhibitionScreen />

    </>)
}